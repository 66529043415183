<template>
  <v-container>
    <base-loading v-if="loading" />

    <ListPrizesHistory v-else-if="prizes" class="my-4" :prizes="prizes" />

    <ErrorRetryComponent
      v-else
      :errorMessage="$t('errorGettingsPrizesHistory')"
      :retryFetchAction="loadContent"
    />
  </v-container>
</template>

<script>
import { prizesCtrl } from "@/controllers";

export default {
  name: "PrizesContainerHistoryComponent",
  components: {
    ListPrizesHistory: () => import("./ListPrizesHistory.vue"),
    ErrorRetryComponent: () =>
      import("@/components/errors/ErrorRetryComponent.vue"),
  },
  data() {
    return {
      prizes: null,
      loading: true,
    };
  },
  async mounted() {
    await this.loadContent();
  },
  methods: {
    async loadContent() {
      try {
        this.loading = true;
        const { prizes } = await prizesCtrl.getPrizesHistory();
        this.prizes = prizes;
      } catch (error) {
        const errorPayload = getErrorPayloadForSnackbar(error);
        this.$store.commit("user/OPEN_SNACKBAR", errorPayload);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
